import '../../../vendor/mmenu-9.3.0/mmenu.min.css';
import '../../../vendor/mmenu-9.3.0/mmenu.min.css';

import * as dompack from 'dompack';

import $ from 'jquery';
import './mobilemenu.scss';
require('../../../vendor/mmenu-9.3.0/mmenu.min.js');

dompack.onDomReady(() => {
  
  let settings = {
    "offCanvas": {
      use: true,
    },
    "navbar": {
      title: " "
    },
  };

  let $menu = new Mmenu( "#my-menu", settings );

  const api = $menu.API;

  $('#toggle-mobile-menu').click(function() {
    api.open();
  });

  $('body').removeClass('mm-wrapper--position-left');

});
