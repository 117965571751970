import * as dompack from 'dompack';

import * as PageNavigation from './page-navigation/';

let listObj = null;

// options: containerId, delayedLoadImages, classImage, numPerPage, filterOptions
// filteroptions: { valueNames: '', query: '' }
// classImage only needed when delayedLoadImages = true (set images using data-src instead of src)
export function setupDefaultPageNavigation({ containerId = '', delayedLoadImages = false, classImage = '', numPerPage = 3, valueNames = []} = {}) {
  if (!dompack.qS('#' + containerId))
    return;

  const pageNavigationOptions =
      { containerId: containerId,
        classPagePrev: 'pagenav__link--prev',
        classPageNext: 'pagenav__link--next',
        delayedLoadImages: delayedLoadImages,
        classImage: classImage,
        numPerPage: numPerPage,
        paginationOptions:
            { paginationClass: 'pagenav__pages',
              left: 1,
              right: 1,
              innerWindow: 2,
            },
      };

  if (valueNames.length) {
    pageNavigationOptions.valueNames = valueNames;
  }

  listObj = PageNavigation.setup(pageNavigationOptions);

  if (dompack.qSA('.pagenav__pages li').length == 1)
    dompack.qS('.pagenav').style.display = 'none';

  // after initialization of list, show everything (prevents bouncing while loading)
  dompack.qS('#' + containerId).style.display = 'block';

  return listObj;
}
