// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// MSO packages
import '@mod-mijnsiteonline/components/slideshow/slideshow';

// main CSS
import './teckop.scss';
import 'font-awesome/css/font-awesome.min.css';

// pages
import './shared/pages';

// components & packages
import './mobilemenu/mobilemenu';
import './smartmenus/smartmenus';
import './widgets/widgets';

// import { setupWebwerfVideos } from 'webwerf-wh-videos';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
require('./web/js/jquery.poptrox.js');
require('./web/js/jquery.scrolly.min.js');
require('./web/js/jquery.scrollex.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
import './web/js/main';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  // setupWebwerfVideos();
});
